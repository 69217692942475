/* eslint-disable react/no-unknown-property */
/* eslint-disable no-constant-condition */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Input, Row, Col, notification, Button, Tooltip, Checkbox, Tree, Select, Divider, Space, Typography,
} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import * as constants from '../../utils/constants';
import moment from 'moment';
import { AddOrUpdateConfigData } from '../../redux/calibration/saga';
const { Option } = Select;

const AddNewDocControlUser = (props) => {
  const {
    userModalVisible, setUserModalVisible, userForm, setIsEdit, isEdit, editRecord, fetchData, setUniqueLocations, uniqueLocations
  } = props;
  const [newItem, setNewItem] = useState('');
  const [locationList, setLocationList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({

  });


  useEffect(() => {
    if (isEdit) {
      setFormData(editRecord)
      console.log(editRecord)
    }

  }, []);
  const setAdddnewItem = (event) => {
    setNewItem(event.target.value);
    // console.log(categoryType)
  }
  const addItem = async (type, newItem) => {
    if (newItem.trim()) {
      // Clear input and add the new item to the location list
      setNewItem(""); // Clear input field
      setUniqueLocations((prevData) => [
        ...prevData,
        { Location: newItem }, // Add new object to the list
      ]);
    }
  };

  const removeItem = (e) => {
    e.preventDefault();
    setNewItem('');
  }


  const handleInputChange = (name, value) => {

    setFormData((prevData) => ({ ...prevData, [name]: value }));

  };

  const handleCancel = () => {
    setIsEdit(false)
    setIsLoading(false)
    setFormData({})
    //onClose();
    setUserModalVisible(false)

  };


  const getCategoryValue1 = (type) => {
    if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
      return formData[type]; // Return the value for the category
    }
    else return null
  };

  const validateEmail = (email) => {
    const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return regexp.test(email);
  };

  const handleOk = async () => {

    if (!formData['UserName']) {
      notification.warning({
        message: 'Enter the Name to proceed!',
      });

      return;
    }

    if (!formData['Location']) {
      notification.warning({
        message: 'Enter the Location to proceed!',
      });
      return;
    }

    if (!formData['Email'] || !validateEmail(formData['Email'])) {
      notification.warning({
        message: 'Enter a valid Email to proceed!',
      });
      return;
    }
    setIsLoading(true); // Stop loading if validation fails
    // If all validations pass
    if (!isEdit) {
      formData['Created_Date'] = moment().format('YYYY-MM-DD').toString();
    }
    formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString();

    console.log('Form data submitted:', formData);
    await AddOrUpdateConfigData(formData)
    // Reset states after submission
    setIsEdit(false);
    await fetchData()
    setIsLoading(false);
    setUserModalVisible(false);
  };



  return (
    <>
      <Modal
        width={750}
        className="pipeline-modal trackrecord-modal Tracker-modal"
        title={isEdit ? 'Edit Doc Control' : 'Add Doc Control'}
        centered
        visible={userModalVisible}
        // footer={isEdit ? [
        //   <Button key="submit1" type="primary" onClick={handleCancel}>
        //     Cancel
        //   </Button>,
        //   <Button key="submit2" loading={createUserLoader} type="primary" >
        //     Update
        //   </Button>
        // ] : [
        //   <Button key="submit3" type="primary" onClick={handleCancel}>
        //     Cancel
        //   </Button>,
        //   <Button key="submit4" type="primary" >
        //     Create
        //   </Button>,
        //   // <Button key="submit5" loading={sendEmailLoader} type={!disableSendEmail ? 'default' : 'primary'} disabled={!disableSendEmail} onClick={() => sendEmail()}>
        //   //   Email login details
        //   // </Button>
        // ]}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary"
            onClick={async () => await handleOk()}
            loading={isLoading}
          >
            {isEdit ? 'Update' : 'Submit'}
          </Button>,
        ]}


      >
        <div>
          <Form
            id="create-user-form"
            name="basic"
            layout="vertical"
            autoComplete="off"
            //form={userForm}

            // onValuesChange={handleFormValuesChange}
            onFinish=""
          >
            <Row gutter={16}>
              <Col lg={12}>
                <Form.Item name="UserName" label="Name" required>
                  <div>
                    <Input onChange={(e) => handleInputChange('UserName', e.target.value)} size="large"
                      value={getCategoryValue1('UserName')}
                      placeholder='Enter Name'
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col lg={12}>

                <Form.Item
                  label="Location"
                  name="Location" required
                >
                  <div>                  <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '0px' }}
                    onChange={(value) => handleInputChange('Location', value)} size="large"
                    showSearch
                    allowClear
                    value={formData['Location']}
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                          <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                          <Typography.Link onClick={(e) => addItem('Location', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            <CheckOutlined />
                          </Typography.Link>
                          <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            <CloseOutlined />
                          </Typography.Link>
                        </Space>
                      </>
                    )}

                  >

                    {uniqueLocations &&
                      uniqueLocations
                        //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                        .map((item, i) => (
                          <Option key={i} value={item.Location}> {/* Use item.category as value */}
                            {item.Location}
                          </Option>
                        ))
                    }
                  </Select>
                  </div>

                </Form.Item>

              </Col>
              <Col lg={24}>
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Email.',
                    },
                    {
                      pattern: true
                        ? constants.EMAIL_PATTERN
                        : undefined,
                      message: 'Provide a valid Email.',
                    }
                  ]}
                //normalize={(value) => utility.trimSpacesInValues(value)}
                >


                  <div>
                    <Input onChange={(e) => handleInputChange('Email', e.target.value)} size="large" placeholder='Enter Email Id'
                      value={getCategoryValue1('Email')} />
                    <span>eg: 1234@mail.com</span>
                  </div>
                </Form.Item>

              </Col>
            </Row>

          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddNewDocControlUser;