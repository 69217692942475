/* eslint-disable linebreak-style */

import { ArrowLeftOutlined, CloudUploadOutlined, EditOutlined, FileExcelOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Tooltip, Typography, Upload } from 'antd';
import ExcelJS from 'exceljs';
import { get } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ProjectModal from './ProjectModal.js';


import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { fetchCalibration, shareCalibration, uploadCalibrationFile } from '../../redux/calibration/action';
import history from '../../services/history';
import { CustomLoadingCellRenderer } from './Uitiles.js';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule,
    MultiFilterModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)


const { Title, Text } = Typography;


const CalibrationMainPage = (props) => {


    const {
        rawDataList,
        isRawListLoading,
        isUploadCalibration,
        calibrationTotalCount,
        onFetchCalibration,
        onShareCalibration
    } = props
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [projectDetailForm] = Form.useForm()
    const [showPipelineModel, setShowPipelineModel] = useState(false)
    const [projectDetails, setProjectDetails] = useState(null);

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([])
    const [layoutSave, setLayoutSave] = useState(false);
    const [layoutLoad, setLayoutLoad] = useState(false);
    const [isPivotMode, setIsPivotMode] = useState(false);
    const [rowData, setRowData] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [pageSize, setPageSize] = useState(100000);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingData, setloadingData] = useState(false);
    const [selectModel, setSelectModel] = useState([]);

    const [calibrationFormModal, setCalibrationFormModal] = useState(false);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [shareCalibrationModal, setShareCalibrationModal] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [calibrationCertificateId, setcalibrationCertificateId] = useState(0);
    const [expiryDateSearch, setExpiryDateSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [paginationClear, setPaginationClear] = useState(false);
    const [editValue, setEditValue] = useState(false);
    const [calibrationRecord, setCalibrationRecord] = useState({});
    const [editData, setEditData] = useState({});
    const [searchCriteria, setSearchCriteria] = useState({});

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const coloumList =
    {

        ID: 'ID',
        Instrument_Company: 'Instrument/Company',
        Identification: 'Identification',
        Code_Model: 'Code/Model',
        Manufacturer_Company: 'Manufacturer/Company',
        SerialNumber: 'Serial Number',
        Frequency: 'Frequency',
        Status: 'Status',
        StorageArea: 'Storage Area',
        CertificateNumber: 'Certificate Number',
        CalibrationDate: 'Calibration Date',
        NextCalibrationDate: 'Next Calibration Date',
        Expiration: 'Expiration',
        Project: 'Project',
        ProjectNo: 'Project Number',

    }
    useEffect(() => {
        generateColumns(coloumList)

    }, [])
    useEffect(() => {
        if (rawDataList.length == 0) {
            onFetchCalibration();
        }
        else
            fetchData(rawDataList)
    }, [])

    const generateColumns = async (columnList) => {
        const columnDefs = Object.keys(columnList)
            .map((key, index) => ({
                field: key,
                headerName: columnList[key],
                resizable: true,
                valueFormatter: (params) => {
                    // Check if the column is a date column
                    const isDateColumn = ['CalibrationDate', 'NextCalibrationDate', 'Expiration'].includes(key);

                    if (isDateColumn) {
                        // Validate and format the date
                        const date = moment(params.value); // Parse date with moment.js
                        return date.isValid() ? date.format('DD-MMM-YYYY') : ''; // Format if valid, empty if invalid
                    }

                    // else {

                    //     const isDateColumn1 = ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key);

                    //     if (isDateColumn1) {
                    //         return Math.floor(Number(params.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    //     }
                    // }
                    // Return value for non-date columns
                    return params.value;
                },


                cellStyle: {

                    textAlign: ['Amount_USD', 'Amount_in_doc_curr', 'Amount_in_local_cur'].includes(key) ? 'right' : 'left'

                }, // Cell alignment
                //editable: true, // Enable editing for cells
                //filter: 'agMultiColumnFilter', // Add filtering

                headerClass: {
                    display: 'flex',
                    justifyContent: 'center', // Center header content
                    alignItems: 'center', // Center vertically
                    textAlign: 'center', // Text alignment
                },
            }));


        // Add the actions column at the end
        columnDefs.push({
            headerName: 'Actions',
            field: 'action',
            pinned: 'right',
            cellRenderer: (params) => {
                const record = params.data;
                const max = record.certificate_history.reduce(function (prev, current) {
                    return (prev.version > current.version) ? prev : current

                })
                return (
                    <div>

                        {/* <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onViewClick(record)}
                                />
                            </span>
                        </Tooltip> */}


                        <Tooltip title="Update" >

                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginRight: '10px' }}>
                                <EditOutlined className='pdfIconRed' onClick={() => onUpdateClick(record)} />
                            </Button>

                        </Tooltip>
                        <Tooltip title="Download" >

                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginRight: '10px' }} href={max?.certificateURL?.fileURL}>
                                <FileExcelOutlined className='pdfIconRed' />
                            </Button>

                        </Tooltip>
                        <Tooltip title="Share Certificate" >

                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginRight: '10px' }}>
                                <ShareAltOutlined className='pdfIconRed' onClick={() => { showShareCalibrationModal(record) }} style={{ marginRight: '00px' }} />
                            </Button>

                        </Tooltip>



                        {/* <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip> */}

                    </div>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });

        // Debugging output for generated columns
        console.log(columnList, columnDefs);

        // Set the column definitions in state
        setColumnDefs(columnDefs);
        return columnDefs;
    };


    // useEffect(() => {
    //     if (rawDataList.length == 0) {
    //         onFetchRawList({ tableName: 'ZPAL_GLOBAL', pageNumber: pageNumber, pageSize: pageSize });

    //     }
    // }, [])
    const fetchData = async (data) => {
        if (data.length > 0) {
            setLayoutLoad(false);
            //generateColumns(data[0]);
            setRowData(data);
            setLayoutLoad(true);
            setLoadingProgress(100);
        }
    };

    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        {
            setIsDeleteLoading(true)
            const obj = {
                columnName: 'Project_Details',
                tableName: 'Project_Details',
                data: selectedData
            };
            console.log(obj);
            await DeleteDetails(obj)


            setSelectedData('');
            onFetchRawList({ tableName: 'Project_Details', pageNumber: pageNumber, pageSize: pageSize });
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);
        }

    }
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }



    const onUpdateClick = (record) => {


        projectDetailForm.setFieldsValue(record)
        //console.log(record)
        seteditRecord(record)
        setIsEdit(true)
        setIsModalVisible(true)
    }

    const onViewClick = (record) => {


        setProjectDetails(record)
        //console.log(record)
        seteditRecord(record)
        //setIsEdit(true)
        setShowPipelineModel(true)
    }



    useEffect(() => {
        console.log('rawDataList updated:', rawDataList.length);

        if (rawDataList)
            fetchData(rawDataList);
    }, [rawDataList]);


    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    // useEffect(() => {
    //     if (layoutSave && gridRef) {
    //         (async () => {
    //             let layouts = {}
    //             await saveGridLayout(gridRef, currentTab, layouts, setLayoutSave);
    //         })();

    //     }


    // }, [layoutSave])

    // useEffect(() => {
    //     if (layoutLoad && gridRef) {
    //         (async () => {
    //             await restoreGridLayout(gridRef, currentTab, setIsPivotMode);
    //             setLayoutLoad(false)
    //         })();


    //     }
    // }, [layoutLoad])



    const gridStyle = useMemo(() => ({ height: "99%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,
            //wrapHeaderText: true,
            // autoHeaderHeight: true,
        };
    }, []);

    const activeKeyLine = 'Cash Flow'
    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: false,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
        console.log("params", params.api)
        if (isRawListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawListLoading]);



    // useEffect(() => {
    //     if (gridRef.current) {
    //         if (loadingData) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [loadingData])

    //

    useEffect(() => {
        if (isRawListLoading) {
            setLoadingProgress(0);

            const interval = setInterval(() => {
                setLoadingProgress((prevProgress) => {
                    const nextProgress = prevProgress + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 500);

            return () => clearInterval(interval); // cleanup on unmount
        }
    }, [isRawListLoading]);

    useEffect(() => {
        if (isRawListLoading && gridRef.current) {
            // Update the overlay with the current progress
            const loadingOverlayTemplate = `
                <div class="custom-loading-overlay">
                    <span>Loading... ${loadingProgress}%</span>
                </div>
            `;
            // gridRef.current.api.showLoadingOverlay();
            // gridRef.current.api.setOverlayLoadingTemplate(loadingOverlayTemplate);
        }
    }, [loadingProgress, isRawListLoading]);

    // Custom status panel component
    // const CustomStatusPanel = ({ refreshInfo }) => {
    //     return <div style={{ paddingTop: '20px' }}>{refreshInfo}</div>;
    // };

    const statusBar = {
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left',
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left',
            },
            // {
            //     statusPanel: () => <CustomStatusPanel refreshInfo={refreshInfo} />, // React component
            //     align: 'right',
            // },
        ],
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        setSelectModel(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    function onCellDoubleClicked(event) {

        console.log(
            event
        );
        if (event)
            onViewClick(event.data)

    }
    const showShareCalibrationModal = async (record) => {
        setcalibrationCertificateId(record.ID)
        setShareCalibrationModal(true);
    }

    const handleCancelUploadFile = () => {
        setFileList([]);
        setBulkUplaodModal(false);
        setDisableFileUpload(false);
    }

    const handleCancelShareCalibrationModal = () => {
        setShareCalibrationModal(false);
        setEmailAddress('');
    }

    const shareCalibration = async () => {
        const emailAddressArray = emailAddress.split(',')
        await onShareCalibration({ calibrationCertificateId, emailAddress: emailAddressArray })
        setShareCalibrationModal(false)
        setEmailAddress('');
    }




    const getPipelineTabpanes = () => {

        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>

                <div style={{ height: 'calc(100vh - 120px)', overflow: 'auto', position: 'relative' }}>
                    <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            rowDragManaged={true}
                            onRowDragEnd={onRowDragEnd}
                            defaultColDef={defaultColDef}
                            rowSelection={rowSelection}
                            onGridReady={onGridReady}
                            sideBar={false}
                            pivotMode={isPivotMode}
                            //
                            pivotPanelShow={"always"}
                            autoGroupColumnDef={autoGroupColumnDef}
                            animateRows={true}
                            onSelectionChanged={onSelectionChanged}
                            // enableFilter={true}
                            statusBar={statusBar}
                            rowHeight={40}
                            headerHeight={50}
                            defaultExcelExportParams={defaultExcelExportParams}
                            defaultCsvExportParams={defaultCsvExportParams}
                            loadingOverlayComponent={CustomLoadingCellRenderer}
                            loadingOverlayComponentParams={{
                                loadingMessage: "Loading all rows...",
                            }}
                            //onCellDoubleClicked={onCellDoubleClicked}
                        />
                    </div>
                </div>

            </div>
        );
    };

    const createExcelTemplate = () => {
        const workbook = new ExcelJS.Workbook();
        const calibrationSheet = workbook.addWorksheet('calibration');
        const headerRow = calibrationSheet.getRow(1)
        headerRow.height = 60
        // Set columns dynamically
        calibrationSheet.columns = Object.keys(coloumList).map((key) => ({
            header: coloumList[key],
            key: key,
            width: 20,
        }));

        return workbook.xlsx.writeBuffer();
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };

    const showBulkUploadModal = () => {
        setBulkUplaodModal(true);
    }
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            // await onUploadCalibrationFile({ fileList, isExcelUpload: 1 });
        } else {
            notification.warn({ message: 'Please select a file to upload.' })
        }
    }

    const handleDownloadTemplate = async () => {
        const data = await createExcelTemplate();
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'template.xlsx';
        link.click();
    };

    return (
        <div className="d-flex flex-column h-100">

            {!showPipelineModel &&
                <div className="flex-auto">

                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">

                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={() => {
                                        history.push('/doc-control');
                                    }} />
                                </Tooltip>
                            </div>
                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '20px', fontFamily: 'auto' }}>
                                List of Calibration
                            </Title>


                            <div className="new-calibration new-project-team">



                                <Text onClick={() => {
                                    setIsEdit(false); setIsModalVisible(true)

                                }} className="ps-3 add-new-team" >
                                    <span className="icon-22">
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Add New
                                </Text>
                                <Text onClick={handleDownloadTemplate} className="ps-3 add-new-team" >
                                    <span className="icon-22">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M12 3v18M5 12l7 7 7-7"></path>
                                        </svg>

                                    </span>
                                    Download template
                                </Text>
                                <Text onClick={showBulkUploadModal} className="ps-3 add-new-team" >
                                    <span className="icon-22">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" transform="rotate(180)">
                                            <path d="M12 3v18M5 12l7 7 7-7"></path>
                                        </svg>

                                    </span>
                                    Bulk Upload
                                </Text>

                            </div>


                        </div>


                        <div className="custom-line" />


                        {getPipelineTabpanes()}



                    </div>


                </div>
            }

            {
                isModalVisible &&
                <ProjectModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}

                    projectDetailForm={projectDetailForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    setShowPipelineModel={setShowPipelineModel}
                    setProjectDetails={setProjectDetails}

                />
            }

            <Modal
                className="pipeline-modal"
                title='Upload Calibration'
                centered
                open={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadCalibration}>
                        {isUploadCalibration ? 'uploading' : 'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>


            <Modal
                className="pipeline-modal"
                title='Share Calibration Certificate'
                centered
                open={shareCalibrationModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelShareCalibrationModal}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary" onClick={shareCalibration}>
                        Share
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Input placeholder="Enter Emails Followed By Comma (,)" style={{ height: '35px' }} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                    <span>eg: 1234@mail.com, 234@email.com</span>
                </div>
            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>


        </div >

    )
};
const mapStateToProps = ({ calibrationReducer }) => {
    const rawDataList = get(calibrationReducer, 'calibrationData', []);
    const isRawListLoading = get(calibrationReducer, 'iscalbrationLoading', false);
    const isUploadCalibration = get(calibrationReducer, 'isUploadCalibration', false);
    const calibrationTotalCount = get(calibrationReducer, 'calibrationTotalCount', null);
    return {
        rawDataList,
        isRawListLoading,
        isUploadCalibration,
        calibrationTotalCount
    }
}

const mapDispatchToProps = {
    onFetchCalibration: fetchCalibration,
    onShareCalibration: shareCalibration,
    onUploadCalibrationFile: uploadCalibrationFile
}

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationMainPage);
