/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Table, Tabs, notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import Admin from './asset-management';
import Spares from './spares-managment';
import UerManagementPage from '../userManagement/UserManagement';
import { authenticateCurrentUser } from '../../services/auth';
import ToolboxAdmin from '../../components/ToolboxAdmin';
import AdminEmployees from '../../components/AdminEmployees';
import EquipmentSubCategory from './EquipmentSubCategory';
import DocControlMangement from '../../components/Calibration/DocControlMangement';

const { TabPane } = Tabs;

const AdminPage = () => {
  const history = useHistory();
  const [group, setGroup] = useState(false);
  const [currentSection, setCurrentSection] = useState('type');

  useEffect(() => {
    authenticateCurrentUser()
      .then((data) => {
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          setGroup(true);
        } else {
          // notification.info({
          //   message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          // });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key) => {
    if (key === "3") { setCurrentSection('type'); }
  };

  return (
    <div className="h-100 d-flex flex-column">

      <div className="flex-fill">
        <Tabs
          className="custom-tab h-100"
          onChange={onChange}
        >
          <TabPane tab="Asset Management" key="1">
            <Admin />
          </TabPane>
          <TabPane tab="User Management" key="2">
            <UerManagementPage />
          </TabPane>
          <TabPane tab="Communications" key="3">
            <ToolboxAdmin />
          </TabPane>
          <TabPane tab="Spares" key="4">
            <Spares />
          </TabPane>
          <TabPane tab="Employees" key="5">
            <AdminEmployees />
          </TabPane>
          <TabPane tab="Manuals & Diagrams" key="6">
            <EquipmentSubCategory 
            />
            </TabPane>
          <TabPane tab="Doc Control" key="7">
            <DocControlMangement />
          </TabPane>
        </Tabs>
      </div>

    </div>
  );
};

export default AdminPage;
