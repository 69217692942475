import {
  FETCH_INVOICE_LIST, FETCH_INVOICE_LIST_SUCCESS, FETCH_INVOICE_LIST_FAILURE, UPDATE_DASHBOARD_INVOICE, UPDATE_DASHBOARD_INVOICE_SUCCESS, UPDATE_DASHBOARD_INVOICE_FAILURE, FETCH_BILLING_LIST, FETCH_BILLING_LIST_SUCCESS, FETCH_BILLING_LIST_FAILURE, FETCH_BILLING_INITIAL_LIST, FETCH_BILLING_INITIAL_LIST_SUCCESS, FETCH_BILLING_INITIAL_LIST_FAILURE, UPDATE_DASHBOARD_BILLING, UPDATE_DASHBOARD_BILLING_SUCCESS, UPDATE_DASHBOARD_BILLING_FAILURE,
  FETCH_BILLING_PDF_URL, FETCH_BILLING_PDF_URL_SUCCESS, FETCH_BILLING_PDF_URL_FAILURE, FETCH_INVOICE_PDF_URL, FETCH_INVOICE_PDF_URL_SUCCESS, FETCH_INVOICE_PDF_URL_FAILURE, SEND_EMAIL_FOR_SIGNATURE_INVOICE, FETCH_INVOICE_MONTH_LIST, FETCH_INVOICE_MONTH_LIST_SUCCESS, FETCH_INVOICE_MONTH_LIST_FAILURE, FETCH_BILLING_MONTH_LIST, FETCH_BILLING_MONTH_LIST_SUCCESS, FETCH_BILLING_MONTH_LIST_FAILURE, FETCH_RATES_LIST, FETCH_RATES_LIST_SUCCESS, FETCH_RATES_LIST_FAILURE, ADD_RATE, ADD_RATE_SUCCESS, ADD_RATE_FAILURE, UPDATE_RATES_LIST, DELETE_RATE, UPLOAD_BULK_RATES_LIST, FETCH_RECHARGE_LIST, FETCH_RECHARGE_LIST_SUCCESS, FETCH_RECHARGE_LIST_FAILURE, ADD_RECHARGE, ADD_RECHARGE_SUCCESS, ADD_RECHARGE_FAILURE, UPDATE_RECHARGE_LIST, DELETE_RECHARGE, FETCH_CHARGE_LIST, FETCH_CHARGE_LIST_SUCCESS, FETCH_CHARGE_LIST_FAILURE, FETCH_RECHARGE_MONTH_LIST, FETCH_RECHARGE_MONTH_LIST_SUCCESS, FETCH_RECHARGE_MONTH_LIST_FAILURE, GENERATE_RECHARGE_EXCEL, RESET_DASHBOARD_BILLING, SEND_EMAIL_FOR_SIGNATURE_BILLING
} from './types';

//Invoices
const fetchInvoiceDashBoardList = (payload) => ({
  type: FETCH_INVOICE_LIST, ...payload
});

const fetchInvoiceDashBoardListSuccess = (payload) => ({
  type: FETCH_INVOICE_LIST_SUCCESS,
  ...payload
});

const fetchInvoiceDashBoardListFailure = () => ({
  type: FETCH_INVOICE_LIST_FAILURE
});

//Invoices
const fetchInvoiceMonthList = (payload) => ({
  type: FETCH_INVOICE_MONTH_LIST, ...payload
});

const fetchInvoiceMonthListSuccess = (payload) => ({
  type: FETCH_INVOICE_MONTH_LIST_SUCCESS,
  ...payload
});

const fetchInvoiceMonthListFailure = () => ({
  type: FETCH_INVOICE_MONTH_LIST_FAILURE
});

//fetch invoice pdf url
const fetchInvoiceDashBoardPdfUrl = (payload) => ({
  type: FETCH_INVOICE_PDF_URL, ...payload
});

const fetchInvoiceDashBoardPdfUrlSuccess = (payload) => ({
  type: FETCH_INVOICE_PDF_URL_SUCCESS,
  ...payload
});

const fetchInvoiceDashBoardPdfUrlFailure = () => ({
  type: FETCH_INVOICE_PDF_URL_FAILURE
});

//
const sendEmailForSignatureInvoice = (payload) => ({
  type: SEND_EMAIL_FOR_SIGNATURE_INVOICE,
  ...payload
});

//
const updateDashboardInvoice = (payload) => ({
  type: UPDATE_DASHBOARD_INVOICE,
  ...payload
});

//billing

//list of billing
const fetchBillingDashBoardList = (payload) => ({
  type: FETCH_BILLING_LIST, ...payload
});

const fetchBillingDashBoardListSuccess = (payload) => ({
  type: FETCH_BILLING_LIST_SUCCESS,
  ...payload
});

const fetchBillingDashBoardListFailure = () => ({
  type: FETCH_BILLING_LIST_FAILURE
});

//Billing
const fetchBillingMonthList = (payload) => ({
  type: FETCH_BILLING_MONTH_LIST, ...payload
});

const fetchBillingMonthListSuccess = (payload) => ({
  type: FETCH_BILLING_MONTH_LIST_SUCCESS,
  ...payload
});

const fetchBillingMonthListFailure = () => ({
  type: FETCH_BILLING_MONTH_LIST_FAILURE
});

//fetch billing pdf url
const fetchBillingDashBoardPdfUrl = (payload) => ({
  type: FETCH_BILLING_PDF_URL, ...payload
});

const fetchBillingDashBoardPdfUrlSuccess = (payload) => ({
  type: FETCH_BILLING_PDF_URL_SUCCESS,
  ...payload
});

const fetchBillingDashBoardPdfUrlFailure = () => ({
  type: FETCH_BILLING_PDF_URL_FAILURE
});
//initial list
const fetchInitialBillingDashBoardList = (payload) => ({
  type: FETCH_BILLING_INITIAL_LIST, ...payload
});

const fetchInitialBillingDashBoardListSuccess = (payload) => ({
  type: FETCH_BILLING_INITIAL_LIST_SUCCESS,
  ...payload
});

const fetchInitialBillingDashBoardListFailure = () => ({
  type: FETCH_BILLING_INITIAL_LIST_FAILURE
});


const updateDashboardBilling = (payload) => ({
  type: UPDATE_DASHBOARD_BILLING,
  ...payload
});

const resetDashboardBilling = (payload) => ({
  type: RESET_DASHBOARD_BILLING,
  ...payload
});

//
const sendEmailForSignatureBilling = (payload) => ({
  type: SEND_EMAIL_FOR_SIGNATURE_BILLING,
  ...payload
});

//Recharge
//GET
const fetchRechargeList = (payload) => ({
  type: FETCH_RECHARGE_LIST,
  ...payload
})
const fetchRechargeListSuccess = (payload) => ({
  type: FETCH_RECHARGE_LIST_SUCCESS,
  ...payload
})

const fetchRechargeListFailure = () => ({
  type: FETCH_RECHARGE_LIST_FAILURE,
})

//GET RECHARGE CHARGE LIST
const fetchRechargeChargeList = (payload) => ({
  type: FETCH_CHARGE_LIST,
  ...payload
})
const fetchRechargeChargeListSuccess = (payload) => ({
  type: FETCH_CHARGE_LIST_SUCCESS,
  ...payload
})

const fetchRechargeChargeListFailure = () => ({
  type: FETCH_CHARGE_LIST_FAILURE,
})

//recharge months and years list
const fetchRechargeMonthList = (payload) => ({
  type: FETCH_RECHARGE_MONTH_LIST, ...payload
});

const fetchRechargeMonthListSuccess = (payload) => ({
  type: FETCH_RECHARGE_MONTH_LIST_SUCCESS,
  ...payload
});

const fetchRechargeMonthListFailure = () => ({
  type: FETCH_RECHARGE_MONTH_LIST_FAILURE
});

//ADD

const addRecharge = (payload) => ({
  type: ADD_RECHARGE,
  ...payload
});

const addRechargeFailure = () => ({
  type: ADD_RECHARGE_FAILURE
});

const addRechargeSuccess = (payload) => ({
  type: ADD_RECHARGE_SUCCESS,
  ...payload
});

//
const deleteRecharge = (payload) => ({
  type: DELETE_RECHARGE,
  ...payload
});


const updateRecharge = (payload) => ({
  type: UPDATE_RECHARGE_LIST,
  ...payload
});

const generateExcelForRecharge = (payload) => ({
  type: GENERATE_RECHARGE_EXCEL,
  ...payload
});

//Recharge Rates
//GET
const fetchRatesList = (payload) => ({
  type: FETCH_RATES_LIST,
  ...payload
})
const fetchRatesListSuccess = (payload) => ({
  type: FETCH_RATES_LIST_SUCCESS,
  ...payload
})

const fetchRatesListFailure = () => ({
  type: FETCH_RATES_LIST_FAILURE,
})

//ADD

const addRate = (payload) => ({
  type: ADD_RATE,
  ...payload
});

const addRateFailure = () => ({
  type: ADD_RATE_FAILURE
});

const addRateSuccess = (payload) => ({
  type: ADD_RATE_SUCCESS,
  ...payload
});

//
const deleteRate = (payload) => ({
  type: DELETE_RATE,
  ...payload
});


const updateRate = (payload) => ({
  type: UPDATE_RATES_LIST,
  ...payload
});

//
const uploadBulkRatesList = (payload) => ({
  type: UPLOAD_BULK_RATES_LIST,
  ...payload
});

export {
  fetchInvoiceDashBoardList,
  fetchInvoiceDashBoardListSuccess,
  fetchInvoiceDashBoardListFailure,
  fetchInvoiceMonthList,
  fetchInvoiceMonthListSuccess,
  fetchInvoiceMonthListFailure,
  fetchInvoiceDashBoardPdfUrl,
  fetchInvoiceDashBoardPdfUrlSuccess,
  fetchInvoiceDashBoardPdfUrlFailure,
  sendEmailForSignatureInvoice,
  updateDashboardInvoice,
  //
  fetchBillingDashBoardList,
  fetchBillingDashBoardPdfUrl,
  fetchBillingDashBoardPdfUrlSuccess,
  fetchBillingDashBoardPdfUrlFailure,
  fetchBillingMonthList,
  fetchBillingMonthListSuccess,
  fetchBillingMonthListFailure,
  fetchBillingDashBoardListSuccess,
  fetchBillingDashBoardListFailure,
  fetchInitialBillingDashBoardList,
  fetchInitialBillingDashBoardListSuccess,
  fetchInitialBillingDashBoardListFailure,
  updateDashboardBilling,
  resetDashboardBilling,
  sendEmailForSignatureBilling,
  //
  fetchRechargeList,
  fetchRechargeListSuccess,
  fetchRechargeListFailure,
  fetchRechargeChargeList,
  fetchRechargeChargeListSuccess,
  fetchRechargeChargeListFailure,
  fetchRechargeMonthList,
  fetchRechargeMonthListSuccess,
  fetchRechargeMonthListFailure,
  addRecharge,
  addRechargeSuccess,
  addRechargeFailure,
  updateRecharge,
  generateExcelForRecharge,
  deleteRecharge,
  //
  fetchRatesList,
  fetchRatesListSuccess,
  fetchRatesListFailure,
  addRate,
  addRateFailure,
  addRateSuccess,
  updateRate,
  uploadBulkRatesList,
  deleteRate
}