/* eslint-disable no-const-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
    Button, Table, Form, Modal, Spin, notification, Tooltip, Typography, Input
} from 'antd';
const { Title, Text } = Typography;
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
    DeleteOutlined, EditOutlined, UnlockOutlined, ArrowDownOutlined, ArrowUpOutlined
} from '@ant-design/icons';
import AddNewDocControlUser from './AddNewDocControlUser';
import { GetTableConfigutaion, DeleteDetails } from '../../redux/calibration/saga';
import { fetchDocControlList } from '../../redux/calibration/action';



const DocControlMangement = (props) => {

    const {
        docControlData,
        isDocCOntrolLoading,
        onFetchDocList
    } = props

    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [userData, setUserData] = useState([]);
    const [uniqueLocations, setUniqueLocations] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [userForm] = Form.useForm();
    const [totalUsers, setTotalUsers] = useState(0);
    const [userModalVisible, setUserModalVisible] = useState(false);
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'ID',
            key: 'ID',
            align: 'center',
            width: '65px',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'UserName',
            key: 'UserName',
            align: 'left',
            width: '300px'
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            align: 'left',
            width: '410px'
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            align: 'left',
            width: '300px'
        },
        {
            title: 'Action',
            key: 'actions',
            render: (record) => {
                if (record) {
                    return (
                        <div>
                            <Tooltip
                                arrowPointAtCenter
                                placement="topRight"
                                title="Edit User Access"
                            >
                                <EditOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </Tooltip>
                            <Tooltip
                                arrowPointAtCenter
                                placement="topLeft"
                                title="Delete User"
                            >
                                <DeleteOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </Tooltip>
                        </div>
                    );
                } else {
                    return <div>-</div>;
                }
            },
            align: 'center'
        }
    ];



    useEffect(() => {
        (async () => {
            if (userData.length == 0)
                await fetchData()
        })();
        // fetchData()
    }, []);


    const fetchData = async () => {
        setShowLoader(true)
        const res = await GetTableConfigutaion('UserManagement_DocControl')
        if (res && res.length > 0) {
            console.log(res)
            // Remove duplicates based on the Location property
            const uniqueLocations = res.filter(
                (item, index, self) =>
                    self.findIndex(obj => obj.Location === item.Location) === index
            )
            setUserData(res)
            setUniqueLocations(uniqueLocations)
        }
        setShowLoader(false)
    }

    // useEffect(() => {
    //     if (docControlData) {
    //         const uniqueLocations = docControlData.filter(
    //             (item, index, self) =>
    //                 self.findIndex(obj => obj.Location === item.Location) === index
    //         )
    //         setUserData(docControlData)
    //         setUniqueLocations(uniqueLocations)
    //     }
    // }, [docControlData]);



    const showAddUserModal = async () => {

        setUserModalVisible(true);
    };

    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        {
            setIsDeleteLoading(true)
            const obj = {
                columnName: 'UserManagement_DocControl',
                tableName: 'UserManagement_DocControl',
                data: selectedData
            };
            console.log(obj);
            await DeleteDetails(obj)

            setSelectedData('');
            await fetchData();
            setIsDeleteLoading(false)
            setIsDeleteModalVisible(false);
        }

    }



    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        console.log(record)
        setSelectedData(record)

    };


    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }



    const onUpdateClick = (record) => {

        setIsEdit(true)
        //console.log(record)
        seteditRecord(record)
        setUserModalVisible(true)
    }





    return (
        <>
            <div className="h-100 overflow-y-hidden  overflow-x-auto">
                <div className="d-flex mt-0.5 justify-content-between  tab-content-title">
                    <div className="d-flex align-items-center">
                        <Title className="mb-0" level={4}>
                            Doc Control of Users
                        </Title>
                        <div className='searchUserManagement'>
                            <Input
                                placeholder='Search By Email'
                                //  onChange={onUserManagment}
                                // value={searchKeyExist}
                                allowClear
                            />
                        </div>
                    </div>
                    <div className="text-right new-calibration">
                        <Text onClick={() => showAddUserModal()} className="ps-3 add-new-calibration"  >
                            <span className="icon-22">
                                <svg>
                                    <use xlinkHref="#add" />
                                </svg>
                            </span>
                            Add New 
                        </Text>
                    </div>
                </div>
                <hr />
                <Table
                    scroll={{ x: totalUsers.length > 0 ? 1000 : 0, y: 'calc(100vh - 255px)' }}
                    pagination={false}
                    dataSource={userData && userData}
                    className="masterUserManagmentTable"
                    loading={{
                        indicator: <Spin />,
                        spinning: showLoader
                    }}
                    columns={columns}
                />
                {userModalVisible &&


                    <AddNewDocControlUser
                        userModalVisible={userModalVisible}
                        setUserModalVisible={setUserModalVisible}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        editRecord={editRecord}
                        fetchData={fetchData}
                        uniqueLocations={uniqueLocations}
                        setUniqueLocations={setUniqueLocations}

                    >

                    </AddNewDocControlUser>
                }


                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Delete Details"
                    centered
                    visible={isDeleteModalVisible}
                    footer={[
                        <Button key="cancel" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                            {isDeleteLoading ? 'Deleting' : 'Delete'}
                        </Button>
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>
                            <p>Deleting all the corresponding and related information.</p>
                            Are you sure you want to delete  ?</Text>
                    </div>
                </Modal>
            </div>
        </>

    );
};

const mapStateToProps = ({ calibrationReducer }) => {
    const docControlData = get(calibrationReducer, 'docControlData', []);
    const isDocCOntrolLoading = get(calibrationReducer, 'isDocCOntrolLoading', false);

    return {
        docControlData,
        isDocCOntrolLoading,

    }
}

const mapDispatchToProps = {
    onFetchDocList: fetchDocControlList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocControlMangement);