import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from './calibrationAxiosConfig';
import {
    addCalibrationFailure,
    addCalibrationSuccess,
    fetchCalibrationSuccess,
    fetchCalibrationFailure,
    shareCalibrationSuccess,
    shareCalibrationFailure,
    uploadCalibrationFileSuccess,
    uploadCalibrationFileFailure,
    updateCalibrationSuccess,
    updateCalibrationFailure,
    fetchDocControlListSuccess,
    fetchDocControlListFailure
} from './action';
import { ADD_CALIBRATION, FETCH_CALIBRATION, SHARE_CALIBRATION, UPLOAD_CALIBRATION_FILE, UPDATE_CALIBRATION, FETCH_DOCCONTROL_LIST } from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';

/** Get all Calibration */
function* fetchCalibration(payload = {}) {
    try {
        let url;

        // if (payload.pageNumber && payload.pageSize && payload.searchKey && payload.expiryDate) {
        //     url = `/calibration-certificate?searchKey=${payload.searchKey}&expiryDate=${payload.expiryDate}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;
        // } else if (payload.pageNumber && payload.pageSize) {
           
        // } else if (payload.expiryDate && payload.searchKey) {
        //     url = `/calibration-certificate?expiryDate=${payload.expiryDate}&searchKey=${payload.searchKey}`;
        // } else if (payload.searchKey) {
        //     url = `/calibration-certificate?searchKey=${payload.searchKey}`;
        // } else if (payload.expiryDate) {
        //     url = `/calibration-certificate?expiryDate=${payload.expiryDate}`;
        // } else {
        //     url = '/calibration-certificate'
        // }
        url = `/GeProcesstList?Table_Name=${'Calibration_Certificate_Details'}&pageSize=${100000}&pageNumber=${1}`;
        const res = yield axiosConfig.get(url);

        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchCalibrationSuccess({ response }));
        } else {
            yield put(fetchCalibrationFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchCalibrationFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Calibration Data.',
            description: `${error}`,
        });
    }
}

/** Function to Add new calibration certificate */
function* addCalibration(payload) {
    try {
        const resFile = yield axiosConfig.get(`/get-pre-signed-url?fileName=${payload.data.certificate[0].name}&contentType=${payload.data.certificate[0].type}`);
        const responseFile = get(resFile, ['data', 'data'], {});

        if (responseFile && responseFile.presignedUrl) {

            //dipatching presigned url with file to aws server
            const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.data.certificate[0]);

            if (presignedResponse.status === 200) {

                payload.data.certificate = responseFile.fileKey;
                const res = yield axiosConfig.post(`AddOrUpdate`, payload.data);
                const response = get(res, ['data', 'data'], {});
                notification.destroy();
                if (res.status === 200) {
                    yield fetchCalibration();
                    notification.success({
                        message: 'Calibration Certificate Added Successfully.',
                    });
                    yield put(addCalibrationSuccess({ response }));
                } else {
                    yield put(addCalibrationFailure());
                    notification.error({
                        message: 'Something went wrong.',
                    });
                }
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(addCalibrationFailure());
            }
        } else {
            yield put(addCalibrationFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }


    } catch (error) {
        console.log(error)
        yield put(addCalibrationFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the Calibration Certificate.',
            description: `${error.error}`,
        });

    }
}

/** Share Calibration */
function* shareCalibration(payload) {
    console.log("payload====================>", payload)
    try {
        let emailURL = '';
        payload.emailAddress.map(a => {
            emailURL += "&emailToDestinations=" + a
        })
        const res = yield axiosConfig.get(`/share-calibration-certificate?calibrationCertificateId=${payload.calibrationCertificateId}${emailURL}`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            notification.success({
                message: 'Calibration Certificate Shared Successfully.',
            });
        } else {
            yield put(shareCalibrationFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(shareCalibrationFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while Sharing the Calibration Data.',
            description: `${error}`,
        });
    }
}

/*Excel Upload*/

function* uploadCalibrationFile({ fileList }) {
    try {
        const res = yield axiosConfig.get(`/get-pre-signed-url?fileName=${fileList[0].name}&contentType=${fileList[0].type}`);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            if (response && response.presignedUrl) {
                //dipatching presigned url with file to aws server
                const presignedResponse = yield axios.put(response.presignedUrl, fileList[0]);
                if (presignedResponse.status === 200) {
                    var obj = {
                        "excelFileData": {
                            "fileKey": response.fileKey,
                            "fileName": response.fileName,
                            "contentType": response.contentType

                        }
                    }

                    //invoking the file to api after uploading file to aws s3 server
                    var invokeRes = yield axiosConfig.post(`/calibration-excel-upload`, obj);
                    if (invokeRes.status === 200) {
                        yield fetchCalibration()
                        notification.success({ message: 'File Successfully uploaded.' });
                        yield put(uploadCalibrationFileSuccess({ response }));
                    } else {
                        notification.error({ message: 'File Uploading Failed. Please try again.' });
                        yield put(uploadCalibrationFileFailure());
                    }
                } else {
                    notification.error({ message: 'File Uploading Failed. Please try again.' });
                    yield put(uploadCalibrationFileFailure());
                }
            }
        } else {
            yield put(uploadCalibrationFileFailure());
            notification.error({
                message: 'Something went wrong.',
            });

        }
    } catch (error) {
        yield put(uploadCalibrationFileFailure());
        notification.destroy();
        notification.error({
            // message: 'A problem occured while fetching the pre signed Url Group.',
            message: error.error
        });
    }
}

/*Update Consumables*/

function* updateCalibration(payload) {
    try {
        if (payload?.data?.certificate && payload?.data?.certificate.length >0) {
            const resFile = yield axiosConfig.get(`/get-pre-signed-url?fileName=${payload.data.certificate[0].name}&contentType=${payload.data.certificate[0].type}`);
            const responseFile = get(resFile, ['data', 'data'], {});
            if (responseFile && responseFile.presignedUrl) {
                const presignedResponse = yield axios.put(responseFile.presignedUrl, payload.data.certificate[0]);
                if (presignedResponse.status === 200) {
                    payload.data.certificate = responseFile.fileKey;
                } else {
                    yield put(updateCalibrationFailure());
                    notification.error({
                        message: 'Something went wrong.',
                    });
                }
            } else {
                notification.error({ message: 'File Uploading Failed. Please try again.' });
                yield put(updateCalibrationFailure());
            }

        }
        const res = yield axiosConfig.post(`AddOrUpdate`, payload.data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchCalibration();
            notification.success({
                message: 'Calibration Certificate Updated Successfully.',
            });
            yield put(updateCalibrationSuccess({ response }));
        } else {
            yield put(updateCalibrationFailure());
            notification.error({
                message: 'Something went wrong.',
            });
        }

    } catch (error) {
        console.log(error)
        yield put(addCalibrationFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the Calibration Certificate.',
            description: `${error.error}`,
        });

    }
}

/** Get all Calibration */
function* fetchDocControlList(payload = {}) {
    try {
        let url;

        // if (payload.pageNumber && payload.pageSize && payload.searchKey && payload.expiryDate) {
        //     url = `/calibration-certificate?searchKey=${payload.searchKey}&expiryDate=${payload.expiryDate}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`;
        // } else if (payload.pageNumber && payload.pageSize) {

        // } else if (payload.expiryDate && payload.searchKey) {
        //     url = `/calibration-certificate?expiryDate=${payload.expiryDate}&searchKey=${payload.searchKey}`;
        // } else if (payload.searchKey) {
        //     url = `/calibration-certificate?searchKey=${payload.searchKey}`;
        // } else if (payload.expiryDate) {
        //     url = `/calibration-certificate?expiryDate=${payload.expiryDate}`;
        // } else {
        //     url = '/calibration-certificate'
        // }

        url = `/GetTableConfig?Table_Name=${('UserManagement_DocControl')}`;

        const res = yield axiosConfig.get(url);

        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchDocControlListSuccess({ response }));
        } else {
            yield put(fetchCalibrationFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchDocControlListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching the Calibration Data.',
            description: `${error}`,
        });
    }
}


function* calibrationWatcher() {
    yield takeLatest(FETCH_CALIBRATION, fetchCalibration);
    yield takeLatest(ADD_CALIBRATION, addCalibration);
    yield takeLatest(SHARE_CALIBRATION, shareCalibration);
    yield takeLatest(UPLOAD_CALIBRATION_FILE, uploadCalibrationFile);
    yield takeLatest(UPDATE_CALIBRATION, updateCalibration);
    yield takeLatest(FETCH_DOCCONTROL_LIST, fetchDocControlList);
}

function* calibrationSagas() {
    yield all([
        call(calibrationWatcher),
    ]);
}

export default calibrationSagas;



export const GetTableConfigutaion = async (tableName) => {

    try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.email) {
            let url;
            url = `/GetTableConfig?Table_Name=${(tableName)}`;


            const res = await axiosConfig.get(url);
            if (res.status === 403) {
                notification.error({
                    message: 'Unauthorized',
                });
                return null;
            } if (res.status === 200) {
                const data = get(res, ['data', 'data'], {});
                //console.log('testType', data)
                return data;
            }
        }
        return [];
    } catch (error) {
        return [];
    }

};

export const AddOrUpdateConfigData = async (data, isNotify = true) => {


    try {
        const res = await axiosConfig.post('/AddOrUpdateDocControlUser', data);

        notification.destroy();
        // console.log(yield res.json)
        if (res.status === 200) {
            const responseData = get(res, ['data', 'data'], {});
            if (isNotify)
                notification.success({
                    message: 'added Successfully.',
                    duration: 3,
                });

            return responseData

        }

        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return null

        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Update Submission Failed.',
            description: `${error}`,
        });
        return null

    }
}


export const DeleteDetails = async (data) => {

    try {
        const resmaster = await axiosConfig.post('/Delete', data);
        //console.log(resmaster)
        if (resmaster.status === 200) {
            notification.success({
                message: 'Deleted Successfully.',
                duration: 3,
            });
            return true
        }
        else {

            notification.error({
                message: 'Something went wrong.',
            });
            return false
        }


    } catch (error) {

        notification.destroy();
        notification.error({
            message: 'Delete Submission Failed.',
            description: `${error}`,
        });
        return false

    }
}