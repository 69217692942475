/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { Button, Col, Form, Modal, Row, Select, Tooltip, Typography, notification } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ExcelJS from 'exceljs';
import moment from 'moment';
import history from '../../services/history';
import TechnicalReportPage from './TechnicalReportPage';

import { fetchProjectList } from '../../redux/CRCETechnical/action';
import { getExportDataListFromDatabse } from '../../redux/CRCETechnical/saga';
import UpdateReport from './UpdateReport';

//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { CustomLoadingCellRenderer } from './Uitiles';

// import axiosConfig from './axiosConfig';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule,
    RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)



const { Option } = Select;
const { Title, Text } = Typography;


const TechnicalPageAgGrid = (props) => {


    const {
        projectList,
        isLoading,
        listCount,
        onProjectList,
        listTotalCount
    } = props
    const data = [
        {
            key: '1',
            MaterialName: '3M Scotchkote 226N FBE_Borealis EP5 10/60M CMPP_Borealis EP5 10/60 PP Tape',
            BatchNumber: 'IN02 5E14_PL101HX01_NH1341E01',
            TestedDate: '2024-01-15',
            ReportedDate: '2024-02-20',
            // Add other properties as needed
        },
        {
            key: '1',
            MaterialName: '3M Scotchkote 226N ',
            BatchNumber: 'IN02 / 4L10 (804)',
            TestedDate: '2024-01-15',
            ReportedDate: '2024-02-20',
            // Add other properties as needed
        },
        // Add more objects as needed
    ];

    const [theme, setTheme] = useState('ag-theme-material-dark'); // Default theme
    const gridRef = useRef();

    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [addNewForm] = Form.useForm()
    const [showTestPage, setshowTestPage] = useState(false)
    const [selectedRow, setSelectedRow] = useState('')
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [current, setCurrent] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [prevPageNumber, setPrevPageNumber] = useState(1);
    const [prevPagesize, setPrevPagesize] = useState(10);
    const [selectedTestRow, setSelectedTestRow] = useState('')
    const [selectedProject, setSelectedProject] = useState('')
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    //const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [resultModalVisible, setResultModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryType, setCategoryType] = useState([]);
    const [materialType, setMaterialType] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const [temperatureType, setTemperatureType] = useState([]);
    const [fileNameType, setFileNameType] = useState([]);
    const [selectedTemperature, setSelectedTemperature] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);
    const [timelineType, setTimelineType] = useState([]);
    const [selectedTimeline, setSelectedTimeline] = useState([]);
    const [projectNameType, setProjectNameType] = useState([]);
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [matrialSearchType, setMatrialSearchType] = useState('')
    const [testTypeSearchType, setTestTypeSearchType] = useState('')
    const [projectNameSearchType, setProjectNameSearchType] = useState('')
    const [timelineSearchType, setTimelineSearchType] = useState('')
    const [temperatureSearchType, setTemperatureSearchType] = useState('')
    const [fileNameSearchType, setFileNameSearchType] = useState('')
    const [searchType, setSearchType] = useState({});
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({
    });
    const [searchFilterCriteria, setSearchFilterCriteria] = useState({
    });
    const [rowData, setRowData] = useState([]);
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    // Create a new instance of the default layout plugin
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: (Toolbar) => (
            <Toolbar>
                {renderDefaultToolbar(transform)}
            </Toolbar>
        ),
    });

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        //  Download: () => <></>,

    });
    const columnList =
    {
        ID: 'ID',
        Material: 'Material',
        TestType: "Test Type",
        ProjectName: 'Project Name',
        Timeline: "Timeline",
        Temperature: "Temperature",
        Company: "Company",
        Contractor: "Contractor",
        FileName: 'File Name',
        ReportNumber: "Report Number",
        TestedDate: "Tested Date",
        ReportedDate: "Report Date"
    }



    const [columnDefs, setColumnDefs] = useState([

    ]);

    const generateColumns = (columnList, onReadParts) => {
        const columnDefs = Object.keys(columnList).map((key, index) => ({
            field: key,
            headerName: columnList[key], // Map the header name from dataIndices
            key: key, // Use the key directly here
            cellRenderer: (params) => {
                const isDateColumn = key === 'TestedDate' || key === 'ReportedDate';
                return isDateColumn && params.value ? moment(params.value).format('DD-MMM-YYYY') : params.value;
            },
            cellStyle: { textAlign: 'center' },
            headerClass: {
                display: 'flex',
                justifyContent: 'center', // Corrected casing
                alignItems: 'center', // Corrected casing
                textAlign: 'center' // Corrected casing
            }
        }));
        // Add the actions column at the end
        columnDefs.push({
            headerName: 'Results',
            field: 'results',
            cellRenderer: (params) => {
                const record = params.data;
                const hasSingleItem = getResultsItems(record.Results);
                let itemValue = '';
                // if (hasSingleItem)
                itemValue = getResultsItemsValue(record.Results);
                // console.log(hasSingleItem)
                // console.log(itemValue)
                return (
                    <>
                        {hasSingleItem || itemValue === '_' ? (
                            <a>{itemValue === '_' ? '-' : itemValue}</a>
                        ) : (
                            <Tooltip title="Results" >
                                <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '00px', }} >
                                    <EyeOutlined style={{ fontSize: '18px' }}
                                        onClick={() => onReadParts(record)}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });


        // Add the actions column at the end
        columnDefs.push({
            headerName: 'Actions',
            field: 'action',
            pinned: 'right',
            cellRenderer: (params) => {
                const record = params.data;
                return (
                    <div>

                        <Tooltip title="View  Report" >

                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginRight: '20px' }}>
                                <FilePdfOutlined className='pdfIconRed' onClick={() => onClickPDFView(record)} />
                            </Button>

                        </Tooltip>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ paddingRight: '0px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>

                    </div>
                );
            },
            cellStyle: { textAlign: 'center' },
            filter: false, // Disable filtering for the Actions column
        });
        setColumnDefs(columnDefs)

        return columnDefs;
    };



    useEffect(() => {
        console.log('rawDataList updated:', projectList.length);


        if (projectList.length > 0)
             generateColumns(columnList, onReadParts)
             setRowData(projectList);
    }, [projectList]);

    const getResultsItems = (data) => {
        if (!data)
            return false
        const dataArray = Object.entries(data)

            .map(([key, value]) => ({
                label: key,
                value: value
            }));

        if (dataArray && dataArray.length == 1) {
            try {
                //JSON.parse(dataArray[0].value)

                if (typeof dataArray[0].value == 'object') {
                    //  console.log('json v', dataArray[0].value, data)
                    return false
                }
            }
            catch (error) {
                return false
            }

            return true
        }
        else
            return false

        // return   typeof data === 'object' && data !== null 
        //console.log(dataArray)
        //return dataArray.length == 1;
    };

    const getResultsItemsValue = (data) => {
        if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
            return '_'; // Return '_' if data is null, undefined, not an object, or empty
        }

        const dataArray = Object.entries(data)
            .map(([key, value]) => ({
                label: key || '_', // Use '_' if the key is null or undefined
                value: value !== null && value !== undefined ? value : '_' // Use '_' if the value is null or undefined
            }));

        return dataArray && dataArray.length > 1 ? dataArray : dataArray[0]?.value || '_';
    };

    const onClickPDFView = (record) => {
        setSelectedTestRow(record)
        setShowPDFViewer(true)

    }


    useEffect(() => {
        var obj = {
            pageSize: 100000,
            pageNumber: 1,
        }
        if(rowData.length == 0)
          onProjectList(obj);
    }, [])
    // useEffect(() => {

    //     // var obj = {
    //     //     pageSize: currentPagesize,
    //     //     pageNumber: 1,
    //     //     searchKey: searchCriteria
    //     // }
    //     // setCurrent(1)
    //     // onProjectList(obj)

    //     const isvalid = hasNonEmptyTag(searchCriteria)
    //     let obj = {
    //         pageSize: isvalid ? currentPagesize : prevPagesize,
    //         pageNumber: isvalid ? 1 : prevPageNumber,
    //         searchKey: searchCriteria
    //     };
    //     //console.log(obj);

    //     (async () => {
    //         // setSelectedMaterial([])
    //         if (isvalid) {
    //             setPrevPagesize(currentPagesize)
    //             setPrevPageNumber(current)
    //         }
    //         setCurrent(obj.pageNumber)
    //         await onProjectList(obj);
    //     })();


    // }, [searchCriteria]);
    useEffect(() => {
        if (!showAddNewModal)
            addNewForm.resetFields();
    }, [showAddNewModal])
    const onReadParts = async (record) => {
        if (record) {
            // console.log(record)
            setSelectedRow(record)
            setResultModalVisible(true)
            //setshowTestPage(true)

        }

    }
    const onUpdateClick = (record) => {

        if (record) {
            // let editData = {
            //     projectName: record.ProjectName,
            //     projectNumber: record.ProjectNumber,

            // };
            addNewForm.setFieldsValue(record)
            addNewForm.setFieldsValue(record.Results)
            //console.log(record)
            seteditRecord(record)
            setIsEdit(true)
            setShowAddNewModal(true)
        }

    }
    // useEffect(() => {
    //     if (editRecord)
    //         setShowAddNewModal(true)

    // }, [editRecord])

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                console.log(jsonObj);

                if (Array.isArray(value) && value.length > 0) {
                    return true;
                } else if (typeof value === 'string' && value.trim() !== '') {
                    return true;
                }
            }
        }
        return false;
    }


    const renderKeyValuePairs = (obj, level = 0) => {
        return Object.entries(obj).map(([key, value]) => {
            if (value !== "_") {
                if (typeof value === 'object' && value !== null) {
                    // Recursively render nested objects
                    return (
                        <div key={key} style={{ marginLeft: `${level * 20}px` }}>
                            <strong>{key}</strong>:
                            {renderKeyValuePairs(value, level + 1)}
                        </div>
                    );
                } else {
                    // Render the key-value pair
                    return (
                        <div key={key} style={{ marginLeft: `${level * 20}px` }}>
                            <strong>{key}</strong>: {value}
                        </div>
                    );
                }
            }
            return null;
        });
    };

    const handleDownloadFilterTable = async (filterData) => {
        setExportLoading(true)
        //console.log(filterData)
        const data = await createExcelTemplate();
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Coating Technical Report.xlsx';
        link.click();
        setExportLoading(false)
    };
 
    const createExcelTemplate = async () => {

        var obj = {
            searchKey: searchCriteria
        }
        const data = await getExportDataListFromDatabse(obj)
        if (data && data.length > 0) {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('Coating Technical Report');

            // Keys to skip
            const keysToSkip = ['ID', 'FileLink', 'totalCount', 'CreationDate', 'UpdatedDate'];

            // Function to flatten the object structure
            const flattenObject = (obj, parentKey = '', result = {}) => {
                for (const key in obj) {
                    const propName = parentKey ? `${parentKey}_${key}` : key;
                    if (keysToSkip.includes(key)) continue;
                    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                        flattenObject(obj[key], propName, result);
                    } else {
                        result[propName] = obj[key];
                    }
                }
                return result;
            };

            const headers = new Set();
            const rows = [];

            data.forEach(item => {
                const flattenedItem = flattenObject(item);
                Object.keys(flattenedItem).forEach(key => {
                    if (!keysToSkip.includes(key)) headers.add(key);
                });
                rows.push(flattenedItem);
            });

            const orderedHeaders = Array.from(headers);
            Array.prototype.myJoin = function (seperator, start, end) {
                if (!start) start = 0;
                if (!end) end = this.length - 1;
                end++;
                return this.slice(start, end).join(seperator);
            };
            // Define columns with hierarchical headers
            sheet.columns = orderedHeaders.map(header => {
                if (header === ('Results_Result')) {
                    return { header: 'Results', key: header, width: 20 };
                } else if (header.startsWith('Results_Result')) {
                    const headerParts = header.split('_');
                    return { header: headerParts.myJoin(' -> ', 1, 2), key: header, width: 20 };
                } else {
                    const headerParts = header.split('_');
                    return { header: headerParts.join(' -> '), key: header, width: 20 };
                }
            });

            // Print the converted object
            //console.log('Converted Object', orderedHeaders, rows);

            // Add rows
            rows.forEach((rowData, index) => {
                const row = sheet.getRow(index + 2); // Start from the second row
                const orderedRowData = orderedHeaders.map(header => rowData[header] !== undefined ? rowData[header] : '');
                orderedRowData.forEach((value, cellIndex) => {
                    row.getCell(cellIndex + 1).value = value;
                });
                // console.log(row.values);
            });
            // Save the workbook to a buffer
            return await workbook.xlsx.writeBuffer();
        } else {
            notification.error({
                message: 'Filter data empty'
            });
        }
    };





    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };


    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            resizable: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            filter: true,

        };
    }, []);
    const activeKeyLine = 'Coating Test Repository'

    const defaultExcelExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.xlsx`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
            sheetName: "Sheet1", // Default sheet name
        };
    }, [activeKeyLine]);
    const defaultCsvExportParams = useMemo(() => {
        return {
            fileName: `${activeKeyLine}.csv`, // Default file name
            allColumns: true, // Include all columns by default
            onlySelected: false, // Include all rows, not just selected ones
        };
    }, [activeKeyLine]);

    const rowSelection = useMemo(() => {
        return {
            mode: 'singleRow',
            checkboxes: false,
            enableClickSelection: true,
        };
    }, []);

    const onGridReady = useCallback((params) => {
        console.log('on Called get ready',isLoading)
        if (isLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isLoading]);

    // // Update loading state
    // useEffect(() => {
    //     if (gridRef.current && gridRef.current.api) {
    //         if (isLoading) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [isLoading]);

    // // Update loading state
    // useEffect(() => {
    //     if (gridRef.current && gridRef.current.api) {
    //         if (isLoading) {
    //             gridRef.current.api.showLoadingOverlay();
    //         } else {
    //             gridRef.current.api.hideOverlay();
    //         }
    //     }
    // }, [isLoading]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left'
            }
        ]
    });

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
            pinned: "left",
        };
    }, []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log('Selected Row Details:', selectedRows);
        seteditRecord(selectedRows)
        // You can do further processing with selectedRows here, e.g., updating state
    };

    return (

        <div className="flex-auto">
            {!showTestPage && !showPDFViewer &&
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">


                        <div className='back_heatp_modal' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined
                                    onClick={() => {
                                        history.push('/technical');
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                            Coating Test Repository
                        </Title>

                        <div className="new-calibration">

                            <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Filters Count: {listCount} / {listTotalCount}</Text>
                            {/* <Tag> Results:{countValue('Results', '_')}</Tag> */}
                            {/* <Tag> status: {listCount}</Tag>
                                <Tag> status: {listCount}</Tag> */}

                            {/* <Button shape="round" key="apply" type="primary" onClick={showDrawer} style={{ marginLeft: '20px' }}>
                                <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>Filter
                            </Button>

                            <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                                onClick={() => handleDownloadFilterTable(projectList)}
                                className="ps-3 add-new-calibration" loading={exportLoading}>

                                Export to Excel
                            </Button> */}
                            {/* <Text onClick={showDrawer} className="ps-3" >
                                <FilterOutlined className='TestrepoIconRed' style={{ marginRight: '5px' }}> </FilterOutlined>
                                Filter
                            </Text> */}



                        </div>


                    </div>
                    <div className="custom-line" />


                    <Row gutter={24}>
                        <Col span={24} >
                            <div className='addTeamDetails'>
                                <div style={{ height: 'calc(112vh - 200px)', overflow: 'auto', position: 'relative' }}>
                                    <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowData={rowData || []}
                                            columnDefs={columnDefs}
                                            rowDragManaged={true}
                                            onRowDragEnd={onRowDragEnd}
                                            defaultColDef={defaultColDef}
                                            rowSelection={rowSelection}
                                            onGridReady={onGridReady}
                                            sideBar={true}
                                            //
                                            pivotPanelShow={"always"}
                                            autoGroupColumnDef={autoGroupColumnDef}
                                            animateRows={true}
                                            onSelectionChanged={onSelectionChanged}
                                            // enableFilter={true}
                                            statusBar={statusBar}
                                            rowHeight={40}
                                            headerHeight={50}
                                            defaultExcelExportParams={defaultExcelExportParams}
                                            defaultCsvExportParams={defaultCsvExportParams}
                                            loadingOverlayComponent={CustomLoadingCellRenderer}
                                            loadingOverlayComponentParams={{
                                                loadingMessage: "Loading all rows...",
                                            }}

                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {
                showAddNewModal &&
                <UpdateReport
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    seteditRecord={seteditRecord}
                    editRecord={editRecord}

                >

                </UpdateReport>
            }
            {showTestPage &&
                <TechnicalReportPage
                    showTestPage={showTestPage}
                    setshowTestPage={setshowTestPage}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                >
                </TechnicalReportPage>

            }
            {showPDFViewer &&
                <div style={{ height: '100%' }}>
                    <div>
                        <div className="d-flex mb-3 align-items-center">
                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={() => {

                                        setShowPDFViewer(false)
                                        //  history.push('/TechnicalPage');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                {showPDFViewer ? 'Report Viewer' : "Projects"}
                            </Title>

                            <div className="new-calibration">



                            </div>


                        </div>
                        <div className='pdf-container'>
                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={selectedTestRow.FileLink}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </div>
                    </div>

                </div>
            }
            <Modal
                className="pipeline-modal"
                title='Results'
                centered
                visible={resultModalVisible}
                footer={[
                    <Button key="apply" type="primary" onClick={() => setResultModalVisible(false)}>
                        {'Ok'}
                    </Button>,

                ]}
            >
                <div>
                    {resultModalVisible && renderKeyValuePairs(selectedRow.Results)}
                </div>
            </Modal>




        </div >

    )
};
const mapStateToProps = ({ CrceTechnicalReducer }) => {
    const projectList = get(CrceTechnicalReducer, 'ProjectList', []);
    const isLoading = get(CrceTechnicalReducer, 'isProjectListLoading', false);

    const listCount = get(CrceTechnicalReducer, 'ProjectListCount', null);
    const listTotalCount = get(CrceTechnicalReducer, 'ProjectListTotalCount', null);


    return {
        projectList,
        isLoading,
        listCount,
        listTotalCount

    }
}

const mapDispatchToProps = {
    onProjectList: fetchProjectList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalPageAgGrid);
