
import React from 'react';

export const CustomLoadingCellRenderer = ({ loadingMessage }) => {
  return (
    <div style={{ textAlign: 'center', padding: '10px', color: '#888' }}>
      <div className="spinner" style={{ marginBottom: '8px' }}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
      <span>{loadingMessage}</span>
    </div>
  );
};

